<template>
  <div class="container" id="detail-container">
    <van-loading v-show="loading" class="loading"></van-loading>
    <!-- <div class="top-banner">
      <div class="banner-img">
        <img v-if="isMobile" class="banner" src="../../../assets/images/jobs/banner-m.png" alt="" />
        <img v-else class="banner" src="../../../assets/images/jobs/banner-pc.png" alt="" />
      </div>
    </div> -->

    <div class="main-page">
      <div class="job-detail">
        <div class="top-info">
          <div class="title ellipsis line2">{{ job.title }}</div>
        </div>
        <div class="labels">
          <div class="label">
            <icon-svg class="icon-label" name="location"></icon-svg>
            <span class="ellipsis line1 work-city"> {{ job.city }} </span>
          </div>
          <span class="bar"></span>
          <div class="label">
            <icon-svg class="icon-label" name="education"></icon-svg>
            <span class="ellipsis line1"> {{ job.education }}</span>
          </div>
          <span class="bar"></span>
          <div class="label">
            <icon-svg class="icon-label" name="category"></icon-svg>
            <span class="ellipsis line1"> {{ job.people }} </span>
          </div>

        </div>
        <div class="scroll-div" id="scroll-div">
          <div class="sub-title"> 截止时间 </div>
          <div class="detail">
            <p>{{ job.end_time }}</p>
          </div>
          <div class="sub-title">职位描述</div>
          <div class="detail">
            <p v-html="job.content"></p>
          </div>
          <div class="sub-title">报名须知</div>
          <div class="detail">
            <p v-html="job.notes"></p>
          </div>
        </div>
      </div>

      <div class="bottom-bar">
        <div class="btn confirm" @click="onApply">我要投递</div>
      </div>
    </div>

  </div>
</template>

<script>

import cookies from "@/libs/util.cookies";

export default {
  name: "JobDetail",
  props: {
    hasResume: {
      Type: Boolean,
      default: false,
    },
  },
  components: {

  },
  data() {
    return {
      share: false,
      jobId: "",
      loading: false,
      job: {}
    };
  },
  computed: {
    isMobile() {
      return document.body.clientWidth < 992;
    },
    isLogin() {
      return cookies.get(process.env.VUE_APP_TOKEN);
    },
  },
  watch: {
    "$route.path": {
      handler: function (newPath, oldPath) {
        if (newPath !== oldPath) {
          
          this.refreshJobDetail();
        }
      },
    },
  },
  created() {
    // 获取职位详情
    this.jobId = this.$route.query.job_id;
    this.refreshJobDetail(true);
  },
  mounted() { },
  methods: {
    // popup面板 -> 跳转职位详情
    goDetail() {
      this.$router.push({
        name: "JobDetail",
        params: {
          jobId: this.jobId,
        },
      });
    },
    scrollTop() {
      this.$nextTick(() => {
        const container = document.getElementById("detail-container");
        if (container) {
          container.scrollTop = 0;
        }        
      });
    },
    // 刷新页面职位信息
    refreshJobDetail(needLoading = false) {
      if (needLoading) this.loading = true;
      this.$api
        .GET_POSITION_DETAIL({ id: this.jobId })
        .then((res) => {
          this.loading = false
          this.job = res;
          this.scrollTop();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 投递
    onApply() {
      var job = this.job
      console.log(job)
      if (job.is_end === 1 ) {
        this.$toast("报名已截止！");
        return
      }

      if (this.isLogin) {
        this.$router.push({
          name: "Resume",
          query: {
            job_id: job.id,
          },
        });
      } else {
        this.$router.push({
          name: "Login",
        });
      }
    }
  },
};
</script>

<style scoped lang="scss">

.container {
  height: calc(100vh - 68px);
  overflow-y: scroll;
  background: white;
}

.top-banner {
  .banner-video {
    width: 100%;
    z-index: 10;
    overflow: hidden;

    .video {
      overflow: hidden;
      // border-radius: 15px 15px 0 0;
    }
  }

  .banner-img img {
    width: 100%;
  }
}

.job-detail {
  padding-top: 18px;
  padding-bottom: 58px;
}

.top-info {
  padding: 0 20px;
  padding-bottom: 12px;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;

  .title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    line-height: 33px;
    margin-right: 20px;
    text-align: left;
  }

  .go-detail {
    width: 60px;
    min-width: 60px;
    margin-right: 30px;
    transform: translateY(1.5px);
    text-decoration: underline;
    color: #e95944;
    font-size: 12px;
    margin-top: 7px;
  }
}

.labels {
  padding: 0 20px;
  padding-bottom: 10px;
  display: flex;
  height: 20px;
  align-items: center;
  border-bottom: 1px solid #f1f4f6;

  .label {
    font-size: 14px;
    color: #666666;
    display: flex;
    align-items: center;
    

    .icon-label {
      width: 15px;
      height: 15px;
      margin-right: 6px;
    }

    span {
      height: 20px;
      line-height: 20px;
    }

    span.work-city {
      max-width: 75px;
    }
  }

  .bar {
    width: 1px;
    height: 15px;
    margin: 0 10px;
    background: #f1f4f6;
  }
}

.scroll-div {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top:30px;

  .release-time {
    font-size: 12px;
    color: #999999;
    line-height: 17px;
    margin-bottom: 14px;
  }

  .card-info {
    padding: 6px 15px;
    background: #f8fafb;
    border-radius: 10px;
    margin-bottom: 25px;

    .van-col {
      padding: 5px;
      display: flex;
      align-items: center;
      font-size: 14px;

      .info-label {
        color: #999999;
      }

      .info-value {
        margin-left: 10px;
        font-weight: bold;
        color: #333333;
        max-width: 70px;
      }
    }
  }

  .sub-title {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 14px;
  }

  .detail {
    font-size: 15px;
    color: #666666;
    word-wrap: break-word;
    word-break: normal;
    white-space: pre-wrap;
    text-align: left;
    max-width: 100%;
    line-height: 28px;
    margin-bottom: 25px;

    p {
      margin: 0;
    }
  }

  .company-info {
    padding: 15px;

    .top {
      display: flex;

      .icon {
        margin-right: 12px;

        img {
          // width: 44px;
          height: 44px;
        }
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .name {
        font-size: 16px;
        font-weight: bold;
        color: #006647;
      }

      .info {
        display: flex;
        align-items: center;
        margin-top: 5px;
        font-size: 12px;
        color: #999999;

        .dot {
          margin: 0px 8px;
          height: 2px;
          width: 2px;
          background: #999999;
        }
      }
    }

    .bottom {
      margin-top: 14px;
      font-size: 15px;
      color: #666666;
      // white-space: pre-wrap;
      text-align: left;
      line-height: 28px;
    }
  }

  .qrcode {
    width: 125px;
    height: 118px;
    align-self: center;

    img {
      width: 125px;
      height: 118px;
    }
  }
}

.bottom-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;

  .collect {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
  }

  .icon-collect {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .btn {
    height: 44px;
    width: 200px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border-radius: 25px;
    border: 1px solid #eaedf0;
    background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
    cursor: pointer;
    // line-height: 44px;
  }

  .confirm {
    color: #ffffff;
  }

  .offline {
    color: rgba(255, 255, 255, 0.3);
  }
}

::v-deep .van-loading {
  width: 100%;
  height: 100%;
  background: white;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.popup {
  overflow: hidden;

  .scroll-div {
    height: 354px;
    max-height: 48vh;
    overflow-y: scroll;
  }
}

@media (min-width: 992px) {

  .container {
    height: calc(100vh - 68px);
    overflow-y: scroll;
    background: white;
  }

  .main-page{
    max-width: 900px;
    margin: 0 auto;
  }

  .job-detail {
    padding-top: 18px;
    padding-bottom: 58px;
  }

  .top-info {
    padding: 0 20px;
    padding-bottom: 12px;
    align-self: flex-start;
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      line-height: 33px;
      margin-right: 20px;
      text-align: left;
    }

    .go-detail {
      width: 60px;
      min-width: 60px;
      margin-right: 30px;
      transform: translateY(1.5px);
      text-decoration: underline;
      color: #e95944;
      font-size: 12px;
      margin-top: 7px;
    }
  }

  .labels {
    padding: 0 20px;
    padding-bottom: 10px;
    display: flex;
    height: 20px;
    align-items: center;

    .label {
      font-size: 14px;
      color: #666666;
      display: flex;
      align-items: center;

      .icon-label {
        width: 15px;
        height: 15px;
        margin-right: 6px;
      }

      span {
        height: 20px;
        line-height: 20px;
      }

      span.work-city {
        max-width: 75px;
      }
    }

    .bar {
      width: 1px;
      height: 15px;
      margin: 0 10px;
      background: #f1f4f6;
    }
  }

  .scroll-div {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top:30px;

    .release-time {
      font-size: 12px;
      color: #999999;
      line-height: 17px;
      margin-bottom: 14px;
    }

    .card-info {
      padding: 6px 15px;
      background: #f8fafb;
      border-radius: 10px;
      margin-bottom: 25px;

      .van-col {
        padding: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;

        .info-label {
          color: #999999;
        }

        .info-value {
          margin-left: 10px;
          font-weight: bold;
          color: #333333;
          max-width: 70px;
        }
      }
    }

    .sub-title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 14px;
    }

    .detail {
      font-size: 15px;
      color: #666666;
      word-wrap: break-word;
      word-break: normal;
      white-space: pre-wrap;
      text-align: left;
      max-width: 100%;
      line-height: 28px;
      margin-bottom: 25px;

      p {
        margin: 0;
      }
    }

    .company-info {
      padding: 15px;

      .top {
        display: flex;

        .icon {
          margin-right: 12px;

          img {
            // width: 44px;
            height: 44px;
          }
        }
      }

      .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .name {
          font-size: 16px;
          font-weight: bold;
          color: #006647;
        }

        .info {
          display: flex;
          align-items: center;
          margin-top: 5px;
          font-size: 12px;
          color: #999999;

          .dot {
            margin: 0px 8px;
            height: 2px;
            width: 2px;
            background: #999999;
          }
        }
      }

      .bottom {
        margin-top: 14px;
        font-size: 15px;
        color: #666666;
        // white-space: pre-wrap;
        text-align: left;
        line-height: 28px;
      }
    }

    .qrcode {
      width: 125px;
      height: 118px;
      align-self: center;

      img {
        width: 125px;
        height: 118px;
      }
    }
  }

  .bottom-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;

    .collect {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666666;
    }

    .icon-collect {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }

    .btn {
      height: 44px;
      width: 200px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      border-radius: 25px;
      border: 1px solid #eaedf0;
      background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
      // line-height: 44px;
      cursor: pointer;
    }

    .confirm {
      color: #ffffff;
    }

    .offline {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  ::v-deep .van-loading {
    width: 100%;
    height: 100%;
    background: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  .popup {
    overflow: hidden;

    .scroll-div {
      height: 354px;
      max-height: 48vh;
      overflow-y: scroll;
    }
  }

}
</style>
